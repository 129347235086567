<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h1 class="page-header">Indications</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x pt-5 pb-2">
          <div class="cell small-12 medium-2 medium-offset-2">
            <button class="button blue-active keep-case expanded">wAMD</button>
          </div>
          <div class="cell small-12 medium-2">
            <button class="button blue-active expanded">DME</button>
          </div>
          <div class="cell small-12 medium-2">
            <button class="button blue-active expanded">PDR</button>
          </div>
          <div class="cell small-12 medium-2">
            <button class="button blue-active expanded">RVO</button>
          </div>
          <div class="cell small-12 medium-2">
            <button class="button blue-active expanded">CNV</button>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2 pt-3 text-right">
            <button class="button giant blue-active">Byooviz<sup>5</sup></button>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of neovascular (wet) age-related macular degeneration (AMD)</p>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of visual impairment due to diabetic macular oedema (DME)</p>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of proliferative diabetic retinopathy (PDR)</p>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of visual impairment due to macular oedema secondary to retinal vein occlusion (branch RVO or central RVO)</p>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of visual impairment due to choroidal neovascularisation (CNV)</p>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="wide orange-line" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2 pt-3 text-right">
            <button class="button blue-active giant">Lucentis<sup>6</sup></button>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of neovascular (wet) age-related macular degeneration (AMD)</p>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of visual impairment due to diabetic macular oedema (DME)</p>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of proliferative diabetic retinopathy (PDR)</p>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of visual impairment due to macular oedema secondary to retinal vein occlusion (branch RVO or central RVO)</p>
          </div>
          <div class="cell small-12 medium-2">
            <p>The treatment of visual impairment due to choroidal neovascularisation (CNV)</p>
          </div>
        </div>
        <div class="grid-x grid-padding-x pb-5">
          <div class="cell small-12 medium-2 medium-offset-10 text-right">
            <button @click="goBack" class="button">Back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'Indications',
  components: {
    Header,
  },
  methods: {
    goBack() {
      this.$router.go('-1');
    },
  },
};
</script>
